import { Link } from "gatsby"
import React from "react"

import banner from "../images/banner2.svg"

const Header = () => (
  <header>
    <div className="top-container">
      <div>
        <h1 className="top-label">Melissa Klein</h1>
      </div>
      <img src={banner} className="top-banner" />
    </div>

    <div className="tabs">
      <Link className="tab" to="/">
        about me
      </Link>

      <Link className="tab" to="/portfolio/">
        portfolio
      </Link>

      <Link className="tab" to="/classes/">
        classes
      </Link>

      <Link className="tab" to="/music/">
        music
      </Link>
    </div>
    <div className="line" />
  </header>
)

export default Header

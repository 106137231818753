/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import resume from "../images/Melissa Klein Resume CV 2021.pdf"

import { faEnvelope, faFile } from "@fortawesome/free-solid-svg-icons"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className="app-container">
        <Header siteTitle={data.site.siteMetadata.title} />
        <div>
          <main>{children}</main>
        </div>
      </div>

      <div className="footer">
        <a className="footer-icon" href="mailto:meliklein99@gmail.com">
          <FontAwesomeIcon icon={faEnvelope} size="2x" />
        </a>
        <a
          className="footer-icon"
          href="https://www.linkedin.com/in/melissa-klein-3bb27b173/"
        >
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>
        <a className="footer-icon" href={resume}>
          <FontAwesomeIcon icon={faFile} size="2x" />
        </a>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
